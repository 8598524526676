.shiftAssignment {
  &-statusSelect {
    & .ant-select-selector {
      height: 36px !important;
      background-color: #65DB75 !important;
    }

    & .ant-select-selection-search-input,
    & .ant-select-selection-placeholder,
    & .ant-select-selection-item span {
      color: #FFF !important;
    }

    &.stop .ant-select-selector {
      background-color: #dbdbdb !important;
    }

    &.cancel .ant-select-selector {
      background-color: #ff120e99 !important;
    }
  }

  &-statusBlock {
    display: inline-flex;
    padding: 10px 25px;
    gap: 10px;

    border-radius: 4px;
    
    & span {
      font-size: 16px;
      line-height: 16px;
    }
    
    &.in-progress {
      background: #E6F9E9;
      & span {
        color: #65DB75;
      }
    }

    &.stop {
      background: #FFF8E1;

      & span {
        color: #FFA629;
      }
    }

    &.cancel {
      background: #FDE3E6;

      & span {
        color: #F54F64;
      }
    }
  }
}

.createShiftAssignment {
  &-table {
    & .ant-table-thead .ant-table-cell {
      color: #7D86A9;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-form {
    & .row-title-4 {
      color: #538DFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    & .ant-col {
      margin-bottom: 40px;

      & .createShiftAssignment-form-row:last-of-type {
        padding-bottom: 0px;
        margin-bottom: 0px;
  
        border-bottom: none;
      }
    }

    & .ant-col:last-child {
      margin-bottom: 0;
    }

    &-row {
      display: grid;
      grid-template-columns: 260px 1fr;
      align-items: center;
      width: 100%;
      max-width: 960px;

      padding-bottom: 8px;
      margin-bottom: 8px;

      border-bottom: 1px solid #E8E8E8;

      & .row-title-3 {
        color: #89939E;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      & .ant-form-item {
        margin-bottom: 0 !important;
      }

      & .my-ant-input-select {
        margin-top: 0;
      }
    }

    & .ant-space {
      width: 100%;
      max-width: 980px;
      display: grid;
      grid-template-columns: 1fr 20px;
      margin-bottom: 40px;

      & svg {
        display: block;
        width: 20px;
        height: 20px;
      }
    }

    & .ant-btn-dashed {
      height: auto;
      max-width: 960px;
      padding: 10px 30px;
    }
  }

  &-modal {
    & .row-title-3 {
      color: #36456C;
      font-size: 20px;
      margin-bottom: 20px;
    }

    & textarea {
      margin-bottom: 40px;
    }

  }

  &-date {
    display: flex;
    width: 155px;
    padding: 12px 20px;
    align-items: center;
    gap: 12px;

    border-radius: 5px;
    background: rgba(240, 242, 245, 0.90);

    & span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; 
    }
    
    &.today {
      width: 338px;
      justify-content: space-between;
      & svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.editShiftAssignment {
  &-modal {
    & .miniTitle {
      color: #36456C;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 42px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      margin-bottom: 8px;

      border-bottom: 1px solid #E8E8E8;

      & .row-title-3 {
        color: #89939E;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      & .ant-form-item {
        margin-bottom: 0 !important;
      }

      & .my-ant-input-select {
        margin-top: 0;
      }
    }

    &-table {
      border-radius: 5px;
      background: rgba(#F0F2F5, .5);
      box-shadow: 0px 2px 2px 0px rgba(34, 43, 55, 0.11);

      margin-bottom: 40px;

      &-header {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 14px 15px;
        margin-bottom: 10px;

        border-radius: 5px 5px 0px 0px;
        background: #538DFF;
        border: none;

        & .title {
          color: #FFF;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
        }

        & svg {
          display: block;
          height: 24px;
          width: 24px;
          fill: white;
        }
      }

      & .editShiftAssignment-modal-row {
        padding-left: 15px;
        padding-right: 15px;
      }
      
      & .row-title-4 {
        display: block;
  
        color: #0E0F11;
        font-size: 16px;
        font-weight: 600;
        
        padding-bottom: 10px;
        margin-bottom: 10px;
  
        border-bottom: 1px solid #E8E8E8;
      }
    }
  }
}